import React from "react";
import styled from "styled-components";

import Button from "../components/Button";

import { CA } from "../consts/misc";

const StyledContractAddress = styled.div`
  /* margin-bottom: 8vh; */

  /* @media (max-width: 600px) {
      font-size: 10.8rem;
    } */
  /* 
  h3 {
    font-family: "Utah WGL Condensed W10 Bold";
    color: white;
    font-size: 1.8vw;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin: 2vh;
    opacity: 0.8;

    @media (max-width: 800px) {
        font-size: 3.8rem;
        margin: 2vh;
    }

    @media (max-width: 500px) {
        font-size: 4.8rem;
        margin: 2vh;
    }
  } */

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.4vh;
  transform: translate(0, -8vh);


  @media (max-width: 600px) {
    gap: 1vh;
    transform: translate(0, calc(-14vh - 80px));

    
  }

  /* @media (max-width: 400px) {
    gap: 2vh;
    transform: translate(0, calc(-14vh - 80px));

  } */

  .ca-text-wrapper {
    max-width: 20vw;

    @media (max-width: 900px) {
      max-width: 30vw;
    }

    img {
      width: 100%;
      opacity: 0.7;
    }
  }
`;

export default function ContractAddress() {
  const [copiedCa, setCopiedCa] = React.useState(false);

  const handleCopyCa = () => {
    navigator.clipboard.writeText(CA).then(
      () => setCopiedCa(true),
      () => setCopiedCa(false)
    );
  };

  React.useEffect(() => {
    const handleClipboardChange = () => {
      navigator.clipboard.readText().then((text) => {
        if (text === CA) {
          setCopiedCa(true);
        } else {
          setCopiedCa(false);
        }
      });
    };

    document.addEventListener("paste", handleClipboardChange);

    return () => {
      document.removeEventListener("paste", handleClipboardChange);
    };
  }, []);

  return (
    <StyledContractAddress>
      {/* <h3>LE CONTRACT ADDRESS</h3> */}
      <div className="ca-text-wrapper">
        <img
          src={process.env.PUBLIC_URL + "/assets/images/ca.svg"}
          alt="logo"
        />
      </div>

      <Button
        title={copiedCa ? "COPIED" : "COPY"}
        action={handleCopyCa}
      ></Button>
    </StyledContractAddress>
  );
}
