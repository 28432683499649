import React from "react";

import styled from "styled-components";

const HeaderWrapper = styled.div`
  width: auto;
  height: 50px;
  border-bottom: 1px solid #000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: white;

  @media (max-width: 600px) {
    height: 40px;
  }

  /* h1 {
    font-family: "Utah WGL Condensed W10 Bold";
    font-size: 1.4vw;
    padding: 0;
    margin: 0;
    letter-spacing: 0.2rem;
    opacity: 0.9;

    @media (max-width: 600px) {
      font-size: 2.4vw;
    }
  } */

  .header-logo-wrapper{
    max-width:10vw;

    @media (max-width: 600px) {
      max-width:16vw;
    }

    img{
      width:100%;
    }
  }
`;

const StyledLink = styled.a`
  &:hover {
    cursor: pointer;
  }

  img {
    width: ${(props) => (props.size ? props.size + "vw" : "1.2vw")};

    @media (max-width: 950px) {
      width: 1.8vw;
    }

    @media (max-width: 600px) {
      width: 2.8vw;
    }
  }
`;

export default function Header() {
  return (
    <HeaderWrapper>
      <StyledLink href="https://twitter.com/solenciagasol" target="_blank">
        <img
          src={process.env.PUBLIC_URL + "/assets/images/twitter.png"}
          alt="Twitter"
        />
      </StyledLink>

      <StyledLink
        href="https://t.me/solenciagaonsol"
        target="_blank"
        size={1.3}
      >
        <img
          src={process.env.PUBLIC_URL + "/assets/images/telegram.png"}
          alt="Telegram"
        />
      </StyledLink>

      {/* <h1>SOLENCIAGA</h1> */}

      <div className="header-logo-wrapper">
        <img
          src={process.env.PUBLIC_URL + "/assets/images/solenciaga-logo.svg"}
          alt="logo"
        />
      </div>

      <StyledLink
        href="https://dexscreener.com/solana/8pcqycrei8ehdodrdz8dvsscixxpyxsk7orzxr2qsnft"
        target="_blank"
      >
        <img
          src={process.env.PUBLIC_URL + "/assets/images/dexscreener.png"}
          alt="Dexscreener"
        />
      </StyledLink>

      <StyledLink
        target="_blank"
        size={1.5}
        style={{ cursor: "default", opacity: 0.35 }}
      >
        <img
          src={process.env.PUBLIC_URL + "/assets/images/shop.png"}
          alt="Shop"
        />
      </StyledLink>
    </HeaderWrapper>
  );
}
