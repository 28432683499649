import React from "react";
import styled from "styled-components";

const StyledButton = styled.a`
  font-family: BB-Regular, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 2.75rem;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  white-space: normal;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-decoration: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: -webkit-box-shadow 0.15s ease-out;
  transition: -webkit-box-shadow 0.15s ease-out;
  transition: box-shadow 0.15s ease-out;
  transition: box-shadow 0.15s ease-out, -webkit-box-shadow 0.15s ease-out;
  -webkit-box-shadow: 0 0 0 0 #aaaaac;
  box-shadow: 0 0 0 0 #aaaaac;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  min-height: 2.5rem;
  margin: 0 auto;
  padding: 0 1rem;
  -webkit-transition: background-color 0.15s ease, border-color 0.15s ease,
    color 0.15s ease, -webkit-box-shadow 0.15s ease-out;
  transition: background-color 0.15s ease, border-color 0.15s ease,
    color 0.15s ease, -webkit-box-shadow 0.15s ease-out;
  transition: background-color 0.15s ease, border-color 0.15s ease,
    color 0.15s ease, box-shadow 0.15s ease-out;
  transition: background-color 0.15s ease, border-color 0.15s ease,
    color 0.15s ease, box-shadow 0.15s ease-out,
    -webkit-box-shadow 0.15s ease-out;
  vertical-align: middle;
  color: #000;
  border: 1px solid #000;
  border-radius: 0.45rem;
  background: #fff;

  min-width:16rem;

  span {
    display: inline-block;
    font-family: "Helvetica";
    font-size: 0.9vw;
    font-weight: 100;
    line-height: 200%;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    cursor: pointer;
    background: transparent;
    white-space: normal;
    text-align: center;

    @media (max-width: 900px) {
        font-size: 1.4vw;
    }

    @media (max-width: 600px) {
        font-size: 1.8vw;
    }

  }
`;

export default function Button({ title, action }) {
  return (
    <StyledButton onClick={action}>
      <span>{title}</span>
    </StyledButton>
  );
}
