import { Helmet } from "react-helmet";

import HomePage from "./pages/Home";

function App() {
  // useReactFontLoader({
  //   url:process.env.PUBLIC_URL + "/assets/fonts/public/utahcondensedbold.ttf.ttf",
  // })

  return (
    <div className="App">
      <Helmet>
        <title>SOLENCIAGA</title>
        <meta name="description" content="High Fashion money" />
        {/* Open Graph meta tags */}
        <meta property="og:title" content="High Fashion money" />
        <meta
          property="og:description"
          content="High Fashion money"
        />
        {/* <meta property="og:image" content="URL_TO_YOUR_IMAGE" /> */}
        {/* Optional: Image to be displayed when shared */}
        {/* <meta property="og:url" content="URL_TO_YOUR_APP" />
        <meta property="og:type" content="website" /> */}
        {/* or "article", "video", etc. */}
      </Helmet>

      <HomePage></HomePage>
    </div>
  );
}

export default App;
