import React from "react";
import styled from "styled-components";

import Header from "../components/Header";

import ContractAddress from "../components/ContractAddress";

import { colors } from "../consts/misc";

const HomeOuterWrapper = styled.div`
  overflow: hidden;
  height: 100vh;
`;

const HomeInnerWrapper = styled.div`
  background-color: ${colors.GREY};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .big-logo-wrapper {
    opacity: 0.8;
    max-width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    /* position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); */
    /* border:1px solid red; */

    .big-logo {
      width: 90%;
    }

    @media (max-width: 600px) {
      transform: translate(0, calc(-1vh - 80px));
    }
  }
`;

// const BigText = styled.h1`
//   font-family: "Utah WGL Condensed W10 Bold";
//   font-size: 14vw;
//   padding: 0;
//   margin: 0;
//   letter-spacing: 0.2rem;
//   opacity: 0.8;

//   /* @media (max-width: 975px) {
//         font-size: 8.5rem;
//       } */
// `;

export default function Home() {
  return (
    <HomeOuterWrapper>
      <HomeInnerWrapper>
        <Header></Header>
        <div className="big-logo-wrapper">
          <img
            className="big-logo"
            src={process.env.PUBLIC_URL + "/assets/images/solenciaga-logo.svg"}
            alt="logo"
          />
        </div>

        <ContractAddress></ContractAddress>
      </HomeInnerWrapper>
    </HomeOuterWrapper>
  );
}
